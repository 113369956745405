/*
 * @Descripttion :
 * @version      :
 * @Author       : min
 * @Date         : 2020-12-23 11:24:03
 * @LastEditors  : min
 * @LastEditTime : 2020-12-23 11:51:18
 */
// 车身颜色
let colorList = [
  {
    text: "黑色",
    color: 'rgb(15, 15, 15)'
  }, {
    text: "白色",
    color: "rgb(255, 255, 255)",
  }, {
    text: "橙色",
    color: 'rgb(255, 121, 1)',
  }, {
    text: "棕色",
    color: 'rgb(165, 42, 42)',
  }, {
    text: "红色",
    color: 'rgb(255, 62, 62)',
  }, {
    text: "粉色",
    color: 'rgb(255, 192, 203)',
  }, {
    text: "绿色",
    color: 'rgb(141, 239, 111)',
  }, {
    text: "蓝色",
    color: 'rgb(56, 173, 240)',
  }, {
    text: "黄色",
    color: 'rgb(243, 231, 42)',
  }, {
    text: "紫色",
    color: 'rgb(241, 111, 243)',
  }, {
    text: "灰色",
    color: 'rgb(170, 170, 170)'
  }, {
    text: "银色",
    color: 'rgb(217, 217, 217)',
  }, {
    text: "米色",
    color: 'rgb(245,245,220)',
  }, {
    text: "其他",
    color: ""
  }];

// 价格
let priceList = [
  {
    text: "3万以下",
    value: 0,
    min: 0,
    max: 30000
  }, {
    text: "3-5万",
    value: 1,
    min: 30000,
    max: 50000
  }, {
    text: "5-10万",
    value: 2,
    min: 50000,
    max: 100000
  }, {
    text: "10-20万",
    value: 3,
    min: 100000,
    max: 200000
  }, {
    text: "20-30万",
    value: 4,
    min: 200000,
    max: 300000
  }, {
    text: "30-40万",
    value: 5,
    min: 300000,
    max: 400000
  }, {
    text: "40万以上",
    value: 6,
    min: 400000,
    max: 999999
  }
];

// 车龄
let carAgeList = [
  {
    text: "不限",
    value: 0,
    min: '',
    max: ''
  }, {
    text: "1年以内",
    value: 1,
    min: 0,
    max: 1
  }, {
    text: "1-3年",
    value: 2,
    min: 1,
    max: 3
  }, {
    text: "3-5年",
    value: 3,
    min: 3,
    max: 5
  }, {
    text: "5-8年",
    value: 4,
    min: 5,
    max: 8
  }, {
    text: "8年以上",
    value: 5,
    min: 8,
    max: 999999
  }
];

// 公里数
let kilometersList = [
  {
    text: "不限",
    value: 0,
    min: 0,
    max: 999999
  }, {
    text: "1万公里以内",
    value: 1,
    min: 0,
    max: 10000
  }, {
    text: "1-3万公里",
    value: 2,
    min: 10000,
    max: 30000
  }, {
    text: "3-6万公里",
    value: 3,
    min: 30000,
    max: 60000
  }, {
    text: "6-10万公里",
    value: 4,
    min: 60000,
    max: 100000
  }, {
    text: "10-20万公里",
    value: 5,
    min: 100000,
    max: 200000
  }, {
    text: "20万公里以上",
    value: 6,
    min: 200000,
    max: 999999
  }
];

// 排放标准
let emissionStandardList = [
  {
    text: "国六",
    value: 6
  }, {
    text: "国五",
    value: 5
  }, {
    text: "国四",
    value: 4
  }, {
    text: "国三",
    value: 3
  }, {
    text: "国二",
    value: 2
  }, {
    text: "国一",
    value: 1
  }, {
    text: "无",
    value: 1
  }
];

let useList = [
  {
    text: "非营运",
    value: 1
  }, {
    text: "营运",
    value: 2
  }, {
    text: "营转非",
    value: 3
  }
];

module.exports = {
  colorList,
  priceList,
  carAgeList,
  kilometersList,
  emissionStandardList,
  useList
}
