<!--
 * @Descripttion : 求购管理
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-23 10:26:31
 * @LastEditors  : min
 * @LastEditTime : 2021-01-28 21:38:42
-->
<template>
  <div class="ml_buy">
    <div class="ml_search_form">
      <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
        <a-form-model-item>
          <a-radio-group button-style="solid" v-model="formInline.status" @change="changeStatus">
            <a-radio-button value="1">
              求购中
            </a-radio-button>
            <a-radio-button value="2">
              关闭
            </a-radio-button>
            <a-radio-button value="0">
              删除
            </a-radio-button>
            <a-radio-button value="-1">
              禁用
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="formInline.keyword" placeholder="搜索文字" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" :disabled="formInline.user === '' || formInline.password === ''">
            查询
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table bordered :data-source="data" :columns="columns" :pagination="pagination" rowKey='id' @change="onShowSizeChange">
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <div class="ml_buy_carlist">
          <h3>意向车型：</h3>
          <template v-for="(item, index) in record.brandList">
            <div :key="index">
              <template v-if="item.data.length > 0">
                <template v-for="(items, indexs) in item.data">
                  <div :key="indexs">
                    {{ items.buyCarname }}
                  </div>
                </template>
              </template>
              <template v-else>
                {{ item.buyBrandName }} {{ item.buySeriesName }} 全部车型
              </template>
            </div>
          </template>
        </div>
      </div>
      <template slot="buyPriceId" slot-scope="record">
        {{ record.buyPriceId |  priceFilter(  record.buyStartPrice, record.buytEndPrice ) }}
      </template>
      <template slot="buyAgeId" slot-scope="record">
        {{ record.buyAgeId | ageFilter(  record.buyStartAge, record.buyEndAge ) }}
      </template>
      <template slot="buyKilometreId" slot-scope="record">
        {{ record.buyKilometre | mileFilter( record.buyKilometre)  }}
      </template>

      <template slot="action" slot-scope="record">
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0" @click="updateBuyStatus(record)">
              禁用
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>
<script>
import { getBuyList, updateBuyStatus } from "../utils/api";
import { priceList, carAgeList } from "../utils/data";

let _columns = [
  {
    title: "求购ID",
    dataIndex: "id",
    align: "center"
  },
  {
    title: "求购说明",
    dataIndex: "buyExplain",
    align: "center"
  },
  {
    title: "价格",
    align: "center",
    scopedSlots: { customRender: "buyPriceId" }
  },
  {
    title: "车龄",
    align: "center",
    scopedSlots: { customRender: "buyAgeId" }
  },
  {
    title: "公里数",
    align: "center",
    scopedSlots: { customRender: "buyKilometreId" }
  },
  {
    title: "车身颜色",
    dataIndex: "buyColorName",
    align: "center"
  }
];
let option = [
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: {
      customRender: "action"
    }
  }
];
export default {
  name: "buyManage",
  data() {
    return {
      formInline: {
        status: "1",
        keyword: ""
      },
      data: [],
      columns: null,
      pagination: {
        position: "bottom",
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        current: 1,
        pageSize: 10,
        total: 50,
        showSizeChanger: true
      }
    };
  },
  filters: {
    priceFilter(buyPriceId, buyStartPrice, buyEndPrice) {
      if (buyPriceId != null) {
        for (let i = 0; i < priceList.length; i++) {
          if (priceList[i].value == buyPriceId) {
            return priceList[i].text;
          }
        }
      } else {
        return parseFloat(buyStartPrice / 10000).toFixed(2) + " - " + parseFloat(buyEndPrice / 10000).toFixed(2) + "万";
      }
    },
    ageFilter(buyAgeId, buyStartAge, buyEndAge) {
      if (buyAgeId != null) {
        for (let i = 0; i < carAgeList.length; i++) {
          if (carAgeList[i].value == buyAgeId) {
            return carAgeList[i].text;
          }
        }
      } else {
        return buyStartAge + "-" + buyEndAge + "年";
      }
    },
    mileFilter(buyKilometre) {
      // if (buyKilometreId != null) {
      //   for (let i = 0; i < kilometersList.length; i++) {
      //     if (kilometersList[i].value == buyKilometreId) {
      //       return kilometersList[i].text;
      //     }
      //   }
      // } else {
      //   return parseFloat(buyKilometre / 10000).toFixed(2) + "-" + parseFloat(buyKilometre / 10000).toFixed(2) + "万公里";
      // }
      return parseFloat(buyKilometre / 10000).toFixed(2) + "万公里以内";
    }
  },
  created() {
    this.columns = _columns.concat(option);
    this.getBuyList();
  },
  methods: {
    onShowSizeChange({ current, pageSize }) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getBuyList();
    },
    getBuyList() {
      const that = this;
      getBuyList({
        current: that.pagination.current,
        count: that.pagination.pageSize,
        status: that.formInline.status,
        keyword: that.formInline.keyword
      }).then(res => {
        res.data.forEach(element => {
          let map = {},
            dest = [];
          element.buybrands.forEach(item => {
            let ai = item;
            if (!map[ai.buySeriesNum]) {
              if (ai.buyModelNum) {
                dest.push({
                  buyBrandNum: ai.buyBrandNum,
                  buyBrandName: ai.buyBrandName,
                  buySeriesNum: ai.buySeriesNum,
                  buySeriesName: ai.buySeriesName,
                  data: [ai]
                });
              } else {
                dest.push({
                  buyBrandNum: ai.buyBrandNum,
                  buyBrandName: ai.buyBrandName,
                  buySeriesNum: ai.buySeriesNum,
                  buySeriesName: ai.buySeriesName,
                  data: []
                });
              }
              map[ai.id] = ai;
            } else {
              for (var j = 0; j < dest.length; j++) {
                var dj = dest[j];
                if (dj.buyModelNum == ai.buyModelNum) {
                  dj.data.push(ai);
                  break;
                }
              }
            }
          });

          element.brandList = dest;
        });
        if (that.pagination.current == 1) {
          that.data = res.data;
        } else {
          that.data = that.data.concat(res.data);
        }
        that.pagination.total = res.count;
      });
    },
    handleSubmit() {
      this.onShowSizeChange({
        current: 1,
        pageSize: 10
      });
    },
    changeStatus() {
      if (this.formInline.status == -1) {
        this.columns = _columns;
      } else {
        this.columns = _columns.concat(option);
      }
      this.onShowSizeChange({
        current: 1,
        pageSize: 10
      });
    },
    updateBuyStatus(e) {
      const that = this;
      that.$confirm({
        title: "是否确定禁用该求购？",
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        centered: true,
        onOk() {
          updateBuyStatus({
            buyStatus: -1,
            id: e.id
          }).then(() => {
            that.$message.success("禁用成功！", 1, () => {
              that.onShowSizeChange({
                current: 1,
                pageSize: 10
              });
            });
          })
        },
        onCancel() { }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ml_buy {
  .ml_buy_carlist {
    background-color: #fff;
    padding: 10px;
  }

  .ml_search_form {
    padding-bottom: 10px;
  }
}
</style>
